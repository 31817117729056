<template>
    <base-layout :public="true">
        <v-layout row wrap text-xs-center py-4>
            <v-flex xs8 sm6 md4 lg2 offset-xs2 offset-sm3 offset-md4 offset-lg5 py-4>
                <!-- <img width="100%" src="" /> -->
            </v-flex>
            <v-flex xs10 sm10 md8 lg6 offset-xs1 offset-sm1 offset-md2 offset-lg3>
                <span class="display-1 font-weight-bold">PAGE NOT FOUND</span>
            </v-flex>
        </v-layout>
    </base-layout>
</template>

<script>
export default {
    name: 'PageNotFound',
    data() {
        return {};
    },
};
</script>
