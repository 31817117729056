<template>
	<base-layout :public="true">
        <v-layout row wrap text-xs-center py-4 style="height: calc(100vh - 64px)">
			<v-flex xs8 sm6 md4 lg2 offset-xs2 offset-sm3 offset-md4 offset-lg5 py-4>
				<!-- <img width="100%" src="" /> -->
			</v-flex>
			<v-btn @click="onLogout()" icon class="btn-logout" x-large>
				<img src="@/assets/header/log_out.svg" style="width: 22px"/>
			</v-btn>
            <v-flex xs10 sm10 md8 lg6 offset-xs1 offset-sm1 offset-md2 offset-lg3>
				<img src="@/assets/icons/date-expired.svg" style="width: 170px; margin: 0 auto 40px auto; display:block;"/>
				<h1 class="display-1 title-expired-text text-center regalBlue--text font-weight-bold">
					Your subscription hasn't started yet
				</h1>
            </v-flex>
        </v-layout>
    </base-layout>
</template>

<script>
import {mapActions, mapMutations} from "vuex";

export default {
    name: 'NotStartedSubscription',
    data() {
        return {};
    },

	methods: {
		...mapMutations('authenticate', {
			setToken: 'setToken',
			setAuthenticateSource: 'setAuthenticateSource',
		}),
		...mapMutations('baseHeaderTab', {
			setCurrentTab: 'setCurrentTab',
			setPreviousTab: 'setPreviousTab',
		}),
		...mapMutations('paginationToolbar', {
			initPagination: 'initPagination',
			setIsDisplayPaginationToolbar: 'setIsDisplayPaginationToolbar',
			setIsLoadingPaginationToolbar: 'setIsLoadingPaginationToolbar',
			setIsLoadingPaginationToolbarAddData: 'setIsLoadingPaginationToolbarAddData',
			setIsDisabledPaginationToolbarAddDataButton: 'setIsDisabledPaginationToolbarAddDataButton',
			setPageSize: 'setPageSize',
		}),
		...mapMutations('marketAssessment', {
			setAvailableMarkets: 'setAvailableMarkets',
			setIsLoadedAvailableMarkets: 'setIsLoadedAvailableMarkets',
			setMarket: 'setMarket',
		}),
		...mapMutations('editDataToolbar', {
			setIsDisplayEditDataToolbar: 'setIsDisplayEditDataToolbar',
			setIsLoadingEditDataToolbar: 'setIsLoadingEditDataToolbar',
			setIsLoadingEditDataToolbarDuplicate: 'setIsLoadingEditDataToolbarDuplicate',
			setIsLoadingEditDataToolbarDelete: 'setIsLoadingEditDataToolbarDelete',
			setIsDisabledEditDataToolbarDuplicateButton: 'setIsDisabledEditDataToolbarDuplicateButton',
			setIsDisabledEditDataToolbarDeleteButton: 'setIsDisabledEditDataToolbarDeleteButton',
		}),

		...mapActions('authenticate', {
			cancelRequests: 'cancelRequests',
			logout: 'logout',
		}),

		onLogout() {
			this.$router.push({ name: 'login' });
			this.logout();
			this.setToken(null);
			this.setAuthenticateSource(null);
			this.setCurrentTab(0);
			this.setPreviousTab(0);
			this.setPageSize(null);
			this.setAvailableMarkets([]);
			this.setIsLoadedAvailableMarkets(false);
			this.setMarket(null);
			localStorage.removeItem('token');
		},
	},

};
</script>

<style>
.title-expired-text{
	font-size: 32px;
}
.btn-logout{
	background-color: #0e3f66;
	border-radius: 100%;
	position: absolute;
	right: 60px;
}
</style>
